<template>
  <main>
    <div class="container">
      <div class="main-container">
        <img src="../assets/auth-logo.png" alt="Запчасти для иностранных грузовиков и прицепов" class="main-container__logo">
        <div class="main-container__content">
          <div class="row justify-content-center">
            <ThisComponent>
              <template #clear-cart>
                <button class="button button--rounded button--large" @click.prevent="clearCart">Очистить корзину</button>
              </template>
              <template #go-to>
                <button class="button button--rounded button--large" @click.prevent="$router.push('/catalog')">Продолжить выбор</button>
              </template>
            </ThisComponent>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from 'vue'
import ThisComponent from 'base/components/Order'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--order')

  this.afterMounted()
}

ThisComponent.methods.beforeMake = function (cart) {
  Vue.$logger.debug('Order | Before make order from Component')
  return new Promise((resolve, reject) => {
    resolve()
  })
}

ThisComponent.methods.afterMake = function () {
  this.$store.dispatch('user/getBalance')

  Vue.$logger.debug('Order | After make from Component')
}

export default {
  components: {
    ThisComponent
  },

  methods: {
    clearCart () {
      this.$store.dispatch('cart/clear')
      this.$router.push('/catalog').catch(()=>{})
    }
  }
}
</script>
